import request from '@/utils/request'
//流程节点 start
export function addProjectNode(param) {//增加流程节点
  return request({
    url: '/admin/workflow/node/add',
    method: 'post',
    data: param
  })
}
export function editProjectNode(param) {//编辑流程节点
  return request({
    url: '/admin/workflow/node/edit',
    method: 'post',
    data: param
  })
}
export function selectProjectNode(param) {//条件查询流程节点选择列表（下拉列表）
  return request({
    url: '/admin/workflow/node/selectList',
    method: 'post',
    data: param
  })
}
export function queryProjectNodeList(param) {//分页条件查询流程节点列表
  return request({
    url: '/admin/workflow/node/queryList',
    method: 'post',
    data: param
  })
}
export function delProjectNode(param) {//删除流程节点
  return request({
    url: '/admin/workflow/node/delete',
    method: 'post',
    data: {
      "id":param
    }
  })
}
export function findRoleList(param) {//系统角色列表
  return request({
    url: '/admin/role/findRoles',
    method: 'post',
    data: param
  })
}
export function findOneProjectNode(param) {//条件查询流程节点详情
  return request({
    url: '/admin/workflow/node/findOne',
    method: 'post',
    data: param
  })
}
//流程节点 end

//流程节点_设置任务 start


//任务模板列表
//“businessType”:业务类型：1 流程节点
//“businessId”:业务ID ， 当类型为 1 时，这个ID就是流程节点ID
export function taskTemplateList(param) {
  return request({
    url: '/admin/task/template/findList',
    method: 'post',
    data: param
  })
}

export function addTaskTemplate(param) {
  return request({
    url: '/admin/task/template/add',
    method: 'post',
    data: param
  })
}
export function editTaskTemplate(param) {
  return request({
    url: '/admin/task/template/edit',
    method: 'post',
    data: param
  })
}
export function deleteTaskTemplate(param) {
  return request({
    url: '/admin/task/template/delete',
    method: 'post',
    data: {
      "id":param
    }
  })
}
export function detailTaskTemplate(param) {
  return request({
    url: '/admin/task/template/findDetail',
    method: 'post',
    data: {
      "id": param
    }
  })
}

//查询流程操作的下拉列表
export function workflowOperationSelectList(param) {
  return request({
    url: '/admin/workflow/operation/selectList',
    method: 'post',
    data: param
  })
}