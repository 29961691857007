<template>
  <div v-loading="loading">
    <div class="title">
      <p>{{ detail.name }}</p>
    </div>
    <el-form>
      <el-row type="flex" justify="end">
        <el-form-item v-if="detail.navButton != null">
          <el-button-group class="pr-5">
            <el-button
              v-if="detail.navButton != null"
              type="primary"
              icon="el-icon-arrow-left"
              @click="changeProject('previous')"
              :disabled="detail.navButton == null"
              >上一项目</el-button
            >
            <el-button
              v-if="detail.navButton != null"
              type="primary"
              @click="changeProject('next')"
              :disabled="detail.navButton.nextId == null"
              >下一项目<i class="el-icon-arrow-right el-icon--right"></i
            ></el-button>
          </el-button-group>
          <el-button
            :type="detail.isFavorite == 1 ? '' : 'primary'"
            @click="followProject"
            >{{ detail.isFavorite == 1 ? "取消关注" : "关注" }}</el-button
          >
        </el-form-item>
      </el-row>
    </el-form>
    <el-tabs type="border-card" @tab-click="tabHandleClick">
      <el-tab-pane label="项目基本信息">
        <template v-if="dataAuth.baseInfo">
          <ul class="bar" style="list-style-type: none">
            <li>
              <b>项目名：</b>
              <span>{{ detail.name }}</span>
            </li>
            <li>
              <b>名称：</b>
              <span>{{ detail.principalName }}</span>
            </li>
            <!-- <li>
        <b>网址：</b>
        <span>{{ detail.principalWebsite }}</span>
      </li> -->
            <li v-if="!loading && detail.principalLocation">
              <b>地址：</b>
              <span>{{
                detail.principalLocation.province +
                detail.principalLocation.city
              }}</span>
            </li>
            <li>
              <b>评级：</b>
              <span>{{ detail.rating }}</span>
            </li>
            <li>
              <b>一句话描述：</b>
              <span>{{ detail.brief }}</span>
            </li>
            <li class="width100">
              <b>投资机构：</b>
              <div
                style="padding: 0 2px"
                v-for="(item, index) in detail.orgList"
                :key="index"
              >
                <div v-if="detail.orgList.length == 1">
                  {{ item.organizationName }}
                </div>
                <div v-else>
                  <span class="forIndex">{{ index + 1 }}</span
                  >. {{ item.organizationName }}
                </div>
              </div>
            </li>
            <!-- <li>
        <b>PM：</b>
        <div
          style="padding: 0 2px"
          v-for="(item, index) in detail.pmList"
          :key="index"
        >
          <div v-if="detail.pmList.length == 1">{{ item.realName }}</div>
          <div v-else>{{ index + 1 }}:{{ item.realName }}</div>
        </div>
      </li>-->
            <li>
              <b>项目BD:</b>
              <span> {{ detail.initialOwnerName }}</span>
            </li>
            <!-- <li>
        <b>项目组成员：</b>
        <div
          style="padding: 0 2px"
          v-for="(item, index) in detail.memberList"
          :key="index"
        >
          <div v-if="detail.memberList.length == 1">{{ item.realName }}</div>
          <div v-else>{{ index + 1 }}:{{ item.realName }}</div>
        </div>
      </li> -->

            <li>
              <b>创建时间:</b>
              <span>{{ detail.createDate | formatDateFilter }}</span>
            </li>
            <li>
              <b>行业：</b>
              <div
                style="padding: 0 2px"
                v-for="(item, index) in detail.industryList"
                :key="index"
              >
                <div v-if="detail.industryList.length == 1">{{ item }}</div>
                <div v-else>{{ index + 1 }}:{{ item }}</div>
              </div>
            </li>
            <li class="width100">
              <b>团队履历：</b>
              <span class="lineFeed">{{ detail.teamResume }}</span>
            </li>
            <li class="width100">
              <b>简介：</b>
              <span class="lineFeed">{{ detail.intro }}</span>
            </li>
            <li class="width100">
              <b>项目备注：</b>
              <span class="lineFeed">{{ detail.remark }}</span>
            </li>
          </ul>
        </template>
        <template v-else-if="dataAuth.baseInfo === false">
          <el-empty :image-size="50">
            <template slot="description">
              <data-auth-apply :info="dataAuthInfo.baseInfo" />
            </template>
          </el-empty>
        </template>
      </el-tab-pane>
      <el-tab-pane label="项目工商信息">
        <template v-if="dataAuth.principalRegInfo">
          <div
            class=""
            v-for="(item, index) in detail.principalOrgInfoList"
            :key="index"
          >
            <h4>{{ index + 1 }}.{{ item.name }}</h4>
            <!-- <div class="flexJfAc" v-show="item.principalRegInfoNameLink">
            <h5>去企查查看更多信息？：</h5>
            <el-link :href="item.principalRegInfoNameLink" target="_blank"
              >{{ item.name }}</el-link
            > 
          </div> -->
            <ul class="bar" style="list-style-type: none">
              <li class="width100">
                <b>公司名：</b>
                <span class="flexJfAc">
                  <div class="name">{{ item.name }}</div>
                  <el-link type="primary" :href="item.principalRegInfoNameLink" target="_blank"
                    >去企查查看更多信息？</el-link
                  >
                </span>
              </li>
              <!-- <li>
        <b>公司ID:</b>
        {{detail.companyId}}
      </li>-->
              <li>
                <b>法人：</b>
                <span> {{ item.legalPersonName }}</span>
              </li>
              <li>
                <b>公司logo:</b>
                <img :src="item.logo" width="80px" height="80px" alt />
              </li>

              <li>
                <b>注册资本：</b>
                <span>{{ item.regCapital }}</span>
              </li>
              <li>
                <b>注册时间：</b>
                <span> {{ item.fromTime | formatDateFilter }}</span>

                <!-- {{detail.fromTime}} -->
              </li>
              <li>
                <b>统一信用代码:</b>
                <span>{{ item.creditCode }}</span>
              </li>
              <li>
                <b>企业类型:</b>
                <span> {{ item.companyOrgType }}</span>
              </li>

              <li>
                <b>工商注册号:</b>
                <span> {{ item.regNumber }}</span>
              </li>
              <li>
                <b>组织机构代码:</b>
                <span>{{ item.orgNumber }}</span>
              </li>
              <li>
                <b>营业期限:</b>
                <span> {{ item.toTime | formatDateFilter }}</span>
              </li>
              <li>
                <b>行业:</b>
                <span> {{ item.industry }}</span>
              </li>
              <li class="width100">
                <b>经营范围:</b>
                <span>{{ item.businessScope }}</span>
              </li>
              <li>
                <b>注册地址:</b>
                <span>{{ item.regLocation }}</span>
              </li>
              <li>
                <b>联系电话:</b>
                <span>{{ item.phoneNumber }}</span>
              </li>
              <li>
                <b>联系电话:</b>
                <span>{{ item.phoneNumber }}</span>
              </li>
              <li>
                <b>轮次:</b>
                <span> {{ detail.rounds }}</span>
              </li>
              <li>
                <b>经营状态:</b>
                <span>{{ item.regStatus }}</span>
              </li>
            </ul>
          </div>
        </template>
        <template v-else-if="dataAuth.principalRegInfo === false">
          <el-empty :image-size="50">
            <template slot="description">
              <data-auth-apply :info="dataAuthInfo.principalRegInfo" />
            </template>
          </el-empty>
        </template>
      </el-tab-pane>
      <el-tab-pane label="联系人信息">
        <template v-if="dataAuth.contactInfo">
          <ul class="bar" style="list-style-type: none">
            <li>
              <b>姓名：</b>
              <span>{{ detail.contactName }}</span>
            </li>
            <li>
              <b>电话：</b>
              <span>{{ detail.contactPhone }}</span>
            </li>
            <li>
              <b>职位：</b>
              <span>{{ detail.contactPosition }}</span>
            </li>
            <li>
              <b>邮箱：</b>
              <span>{{ detail.contactMail }}</span>
            </li>
            <li>
              <b>微信：</b>
              <span>{{ detail.contactWechat }}</span>
            </li>
          </ul>
        </template>
        <template v-else-if="dataAuth.contactInfo === false">
          <el-empty :image-size="50">
            <template slot="description">
              <data-auth-apply :info="dataAuthInfo.contactInfo" />
            </template>
          </el-empty>
        </template>
      </el-tab-pane>
      <el-tab-pane label="项目文件">
        <template v-if="dataAuth.documentFile || dataAuth.dingTalkFile">
          <template slot="title">
            项目文件<i class="header-icon el-icon-document"></i>
          </template>
          <documentList
            v-if="documentListShow"
            :type="1"
            :projectInfo="detail2"
            :dataAuth="dataAuth"
            :dataAuthInfo="dataAuthInfo"
          ></documentList>
        </template>
        <template
          v-else-if="
            dataAuth.documentFile === false && dataAuth.dingTalkFile === false
          "
        >
          <el-empty :image-size="50">
            <template slot="description">
              <data-auth-apply
                v-if="dataAuth.documentFile === false"
                title="暂无权限查看项目文件"
                :info="dataAuthInfo.documentFile"
              />
              <data-auth-apply
                v-if="dataAuth.dingTalkFile === false"
                title="暂无权限查看网盘文件"
                :info="dataAuthInfo.dingTalkFile"
              />
            </template>
          </el-empty>
        </template>
      </el-tab-pane>
      <el-tab-pane label="历史操作">
        <div class="other">
          <el-tabs type="border-card" v-model="activeName">
            <el-tab-pane label="项目评估历史" name="first">
              <template v-if="dataAuth.evaluationInfo">
                <el-table
                  border
                  :data="detail.evaluationRecordList"
                  :span-method="objectSpanMethod"
                >
                  <el-table-column
                    label="模版"
                    prop="templateName"
                  ></el-table-column>
                  <el-table-column
                    label="流程节点"
                    prop="workflowNodeName"
                  ></el-table-column>
                  <el-table-column
                    label="评估人"
                    prop="evaluatorName"
                  ></el-table-column>
                  <el-table-column
                    label="评估时间"
                    prop="createDate"
                    width="160"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.createDate | formatDateFilter }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="fileList"
                    width="180"
                    align="left"
                    label="任务操作"
                  >
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        round
                        @click="openAssessment(scope.row)"
                      >
                        打开评估
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
              <template v-else-if="dataAuth.evaluationInfo === false">
                <el-empty :image-size="50">
                  <template slot="description">
                    <data-auth-apply :info="dataAuthInfo.evaluationInfo" />
                  </template>
                </el-empty>
              </template>
            </el-tab-pane>
            <el-tab-pane label="项目会议历史" name="second">
              <!--          <template v-if="dataAuth.meetingList">-->
              <el-table border :data="detail.meetingList">
                <el-table-column
                  label="会议主题"
                  prop="subject"
                ></el-table-column>
                <el-table-column label="会议日期" prop="createDate" width="160">
                  <template slot-scope="scope">
                    <span>{{ scope.row.beginTime | formatDateFilter }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="与会外部人员"
                  prop="externalUsers"
                  width="110"
                ></el-table-column>
                <el-table-column label="内部人员" prop="place">
                  <template slot-scope="scope">
                    <span
                      class="span-tag-list"
                      v-for="item in scope.row.internalUserList"
                      :key="item.id"
                    >
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.realName"
                        placement="top"
                      >
                        <span
                          >{{ item.realName }}<span class="d">,</span>
                        </span>
                      </el-tooltip>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="待办事项" prop="place">
                  <template slot-scope="scope">
                    <span
                      class="span-tag-list"
                      v-for="(item, index) in scope.row.matterList"
                      :key="item.id"
                    >
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.content"
                        placement="top"
                      >
                        <span
                          ><span class="forIndex">{{ index + 1 }}</span
                          >.{{ item.content }}
                        </span>
                      </el-tooltip>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="会议内容" prop="content">
                  <template slot-scope="scope">
                    <el-button @click="openContent(scope.row)" slot="reference"
                      >会议内容</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!--          </template>-->
              <template v-if="dataAuth.meetingList === false">
                <el-empty :image-size="50">
                  <template slot="description">
                    <data-auth-apply :info="dataAuthInfo.meetingList" />
                  </template>
                </el-empty>
              </template>
            </el-tab-pane>
            <el-tab-pane label="项目任务进展" name="fourth">
              <template v-if="dataAuth.taskInfo">
                <div
                  class=""
                  v-for="(item, index) in detail.workflowTaskList"
                  :key="index"
                >
                  <h3>
                    <span class="forIndex">{{ index + 1 }}</span
                    >.{{ item.name }}
                  </h3>
                  <el-table
                    border
                    row-key="id"
                    style="width: 100%"
                    :data="item.taskList"
                  >
                    <!-- <el-table-column type="index" label="#" width="40"></el-table-column> -->
                    <el-table-column
                      label="流程节点"
                      prop="nodeName"
                    ></el-table-column>
                    <el-table-column
                      label="任务"
                      prop="name"
                      width="200"
                    ></el-table-column>
                    <el-table-column
                      label="负责人"
                      prop="ownerName"
                    ></el-table-column>

                    <el-table-column label="任务状态" prop="status" width="65">
                      <template slot-scope="scope">
                        <div
                          :class="[
                            scope.row.status == 1
                              ? 'status1'
                              : scope.row.status == 2
                              ? 'status2'
                              : scope.row.status == 3
                              ? 'status3'
                              : scope.row.status == 4
                              ? 'status4'
                              : scope.row.status == 5
                              ? 'status5'
                              : '',
                          ]"
                        >
                          {{
                            scope.row.status == 1
                              ? "待启动"
                              : scope.row.status == 2
                              ? "进行中"
                              : scope.row.status == 3
                              ? "已完成"
                              : scope.row.status == 4
                              ? "已取消"
                              : scope.row.status == 5
                              ? "已暂停"
                              : "未知"
                          }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="进度" prop="progress">
                      <template slot-scope="scope">
                        <div>{{ scope.row.progress }}%</div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="fileList"
                      width="165"
                      align="center"
                      label="文件"
                    >
                      <template slot-scope="scope">
                        <a
                          href="#"
                          style="cursor: pointer"
                          v-for="(item, index) in scope.row.fileList"
                          :key="index"
                          @click="handleExport(item)"
                        >
                          <p v-if="item.originalFileName">
                            <span class="forIndex">{{ index + 1 }}</span
                            >.{{ item.originalFileName }}
                            <span
                              v-if="dataAuth.documentFile === false"
                              class="no-permission"
                              >暂无权限查看</span
                            >
                          </p>
                          <p v-else>
                            <span class="forIndex">{{ index + 1 }}</span
                            >.{{ item.name }}
                            <span
                              v-if="dataAuth.documentFile === false"
                              class="no-permission"
                              >暂无权限查看</span
                            >
                          </p>
                        </a>
                      </template>
                    </el-table-column>
                    <!-- <el-table-column
                label="风险及应对"
                prop="remark"
              ></el-table-column> -->
                    <el-table-column
                      label="计划开始日期"
                      prop="createDate"
                      width="155"
                    >
                      <template slot-scope="scope">
                        <span v-show="scope.row.planBeginTime">{{
                          scope.row.planBeginTime | formatDateFilter
                        }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="计划结束日期"
                      prop="createDate"
                      width="155"
                    >
                      <template slot-scope="scope">
                        <span>{{
                          scope.row.planEndTime | formatDateFilter
                        }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right">
                      <template slot-scope="scope">
                        <el-button
                          type="primary"
                          size="mini"
                          @click="openTaskCommen(scope.row)"
                          >评论
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </template>
              <template v-else-if="dataAuth.taskInfo === false">
                <el-empty :image-size="50">
                  <template slot="description">
                    <data-auth-apply :info="dataAuthInfo.taskInfo" />
                  </template>
                </el-empty>
              </template>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-tab-pane>
      <el-tab-pane label="评论">
        <div class="comment newComment" v-if="projectId">
          <comment :subjectType="subjectType" :subjectId="projectId"></comment>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      title="会议内容"
      :visible.sync="meetingContentDialog"
      width="60%"
      :before-close="handleMeetingContentClose"
      append-to-body
    >
      <span v-html="meetingContent">{{ meetingContent }}</span>
      <!-- 评论 -->
      <el-divider content-position="center">评论</el-divider>
      <div class="comment" v-if="commentMeetingId">
        <comment
          :subjectType="commentMeetingType"
          :subjectId="commentMeetingId"
        ></comment>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="meetingContentDialog = false">取 消</el-button> -->
        <el-button type="primary" @click="meetingContentDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="预览"
      append-to-body
      center
      :visible.sync="dialogVisible"
      width="70%"
    >
      <div class="pdfUrlF" v-if="pdfUrl"></div>
      <div class="imgUrl" v-if="imgUrl">
        <el-image ref="imgUrl" :src="imgUrl">
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 任务评论 -->
    <el-dialog
      title="任务评论"
      :visible.sync="taskCommentDialog"
      width="60%"
      :before-close="taskContentClose"
      append-to-body
    >
      <!-- 评论 -->
      <!-- <el-divider content-position="center">评论</el-divider> -->
      <div class="comment" v-if="taskCommentDialog">
        <comment :subjectType="taskSubjectType" :subjectId="taskId"></comment>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="taskCommentDialog = false">取 消</el-button> -->
        <el-button type="primary" @click="taskCommentDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formatDate } from "../../common/date";
import {
  findProjectAllDetail,
  followProject,
  unFollowProject,
} from "@/api/projectManage";
import documentList from "@/components/myMission/projectDocuments/documentList.vue";
import comment from "@/components/comment/comment.vue";
import DataAuthApply from "@/components/dataAuth/dataAuthApply";

export default {
  name: "detailsDialog",
  props: {
    msPd: {
      type: Object,
      default: () => {},
    },
    projectInfo: {
      type: Object,
      default: () => {},
    },
    queryParams: {
      type: Object,
      default: () => {},
    },
    proId: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      detail: {},
      detail2: {},
      activeName: "first",
      loading: true,
      meetingContent: null,
      meetingContentDialog: false,
      dialogVisible: false,
      pdfUrl: null,
      imgUrl: null,
      scale: 100,
      documentListShow: false,
      dataAuth: {},
      dataAuthInfo: {
        baseInfo: {
          event: "base_info",
          target: "t_project",
          targetId: this.projectInfo.id,
          ability: 10,
        },
        businessInfo: {
          event: "business_info",
          target: "t_project",
          targetId: this.projectInfo.id,
          ability: 10,
        },
        principalRegInfo: {
          event: "principal_reg_info",
          target: "t_project",
          targetId: this.projectInfo.id,
          ability: 10,
        },
        documentFile: {
          event: "document_file",
          target: "t_project",
          targetId: this.projectInfo.id,
          ability: 10,
        },
        dingTalkFile: {
          event: "ding_file",
          target: "t_project",
          targetId: this.projectInfo.id,
          ability: 10,
        },
        taskInfo: {
          event: "task_info",
          target: "t_project",
          targetId: this.projectInfo.id,
          ability: 10,
        },
        contactInfo: {
          event: "contact_info",
          target: "t_project",
          targetId: this.projectInfo.id,
          ability: 10,
        },
        meetingList: {
          event: "meeting_list",
          target: "t_project",
          targetId: this.projectInfo.id,
          ability: 10,
        },
        evaluationInfo: {
          event: "evaluation_info",
          target: "t_project",
          targetId: this.projectInfo.id,
          ability: 10,
        },
      },
      projectId: null,
      subjectType: "PROJECT_DETAIL",
      commentMeetingType: "MEETING_DETAIL",
      commentMeetingId: null,
      taskId: null,
      taskSubjectType: "TASK_DETAIL",
      taskCommentDialog: false,
      principalRegInfoNameLink: null,
    };
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      if (time) return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  computed: {},
  watch: {
    proId: {
      handler(newVal, old) {
        if (newVal) {
          this.projectId = JSON.parse(JSON.stringify(newVal));
          this.findProjectAllDetail();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  components: {
    DataAuthApply,
    documentList,
    comment,
  },
  mounted() {},
  methods: {
    tabHandleClick(tab, event) {
      console.log(tab.index);
      let index = tab.index;
      if (index == 0) {
      } else if (index == 1) {
      } else if (index == 3) {
        this.documentListShow = true;
      }
    },
    changeProject(type) {
      if (type == "previous") {
        if (this.detail.navButton.prevId != null) {
          this.projectId = JSON.parse(
            JSON.stringify(this.detail.navButton.prevId)
          );
          this.findProjectAllDetail();
        } else {
          this.$message({ type: "info", message: ",没有上一个!" });
        }
      } else {
        if (this.detail.navButton.nextId != null) {
          this.projectId = JSON.parse(
            JSON.stringify(this.detail.navButton.nextId)
          );
          this.findProjectAllDetail();
        } else {
          this.$message({ type: "info", message: ",没有下一个!" });
        }
      }
    },
    followProject() {
      let that = this;
      let title = that.detail.isFavorite == 1 ? "取消关注" : "关注";
      let info = {
        refType: "project",
        refId: that.detail.id,
      };
      this.$confirm(
        "确认" + title + "项目 [" + that.detail.name + "] 吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          if (that.detail.isFavorite == "0") {
            followProject(info).then((response) => {
              if (response.success) {
                that.$message({ type: "success", message: "关注成功!" });
                that.findProjectAllDetail();
              } else {
                that.$message({
                  type: "info",
                  message: response.message,
                });
              }
            });
          } else if (that.detail.isFavorite == "1") {
            unFollowProject(info).then((response) => {
              if (response.success) {
                that.$message({ type: "success", message: "取消关注成功!" });
                that.findProjectAllDetail();
              } else {
                this.$message({
                  type: "info",
                  message: response.message,
                });
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    openTaskCommen(row) {
      console.log(row);
      this.taskId = row.id;
      this.taskCommentDialog = true;
    },
    taskContentClose() {
      this.taskCommentDialog = false;
    },
    collapseChange(array) {
      if (array) {
        this.documentListShow = true;
      }
    },
    openContent(val) {
      this.meetingContent = val.content;
      this.commentMeetingId = val.id;
      this.meetingContentDialog = true;
    },
    handleMeetingContentClose() {
      this.meetingContentDialog = false;
    },
    handleExport(item) {
      //下载文件
      // let userId = window.localStorage.getItem("userId");
      // let memberList = JSON.parse(JSON.stringify(this.detail.memberList));
      // let download = memberList.some((value, index) => value.userId == userId);
      // if (download) {
      //   //下载文件
      //   var elemIF = document.createElement("iframe");
      //   elemIF.src = item.url;
      //   elemIF.style.display = "none";
      //   document.body.appendChild(elemIF);
      // } else {
      //   this.$message({ type: "warning", message: "您没有下载权限!" });
      // }
      let fileType = item.url.split(".").pop();
      console.log(fileType);
      this.$router.push({
          name: "officeOnLine",
          params: { file: item, url: item.url,fileType:fileType },
        });
        return false;
      if (
        fileType == "docx" ||
        fileType == "doc" ||
        fileType == "xlsx" ||
        fileType == "xls" ||
        fileType == "ppt" ||
        fileType == "pptx"
      ) {
        console.log(item.url);
        let url = encodeURIComponent(item.url);
        window.open("https://view.officeapps.live.com/op/view.aspx?src=" + url);
      } else if (fileType == "pdf") {
        // console.log(item.url);
        this.imgUrl = null;
        let origin = window.location.origin;
        let url = origin + "/pdf/web/viewer.html?file=" + item.url;
        window.open(url);
      } else if (
        fileType == "jpg" ||
        fileType == "jpeg" ||
        fileType == "png" ||
        fileType == "gif"
      ) {
        this.pdfUrl = null;
        this.imgUrl = item.url;
        this.dialogVisible = true;
      } else {
        this.$message({ type: "warning", message: "请下载后查看!" });
        window.open(item.url);
      }
    },
    openAssessment(row) {
      this.$store.commit("setAssessmentProjectId", row.projectId);
      const { href } = this.$router.resolve({
        name: `myMission`,
        query: {
          projectId: row.projectId,
          projectName: row.projectName,
          projectWorkflowId: row.workflowId,
          projectWorkflowDetailId: row.projectWorkflowDetailId,
        },
      });

      window.open(href);
    },
    findProjectAllDetail() {
      let that = this;
      that.loading = true;
      let info = {
        id: that.projectId,
        ...that.queryParams,
      };
      findProjectAllDetail(info).then((res) => {
        if (res.success) {
          that.detail = res.data;
          // this.getSpanArr(this.detail.workflowTaskList);
          that.detail2 = {
            projectId: res.data.id,
            projectType: res.data.type,
          };

          if (that.detail.principalOrgInfoList) {
            that.detail.principalOrgInfoList.forEach((item, index) => {
              if (item.name) {
                item.principalRegInfoNameLink =
                  "https://www.qcc.com/web/search?key=" + item.name;
              }
            });
          }

          that.dataAuth = that.detail.projectAuthResult;
          that.loading = false;
        }
      });
    },
    getSpanArr(data) {
      this.spanArr = [];
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          if (
            data[i].projectWorkflowDetailId ===
            data[i - 1].projectWorkflowDetailId
          ) {
            // 如果moduleName相等就累加，并且push 0  这里是根据一样的moduleName匹配
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            // 不相等push 1
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
  },
  created() {},

  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.comment {
  text-align: left;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    width: 49%;
    // min-height: 30px;
    // line-height: 50px;
    padding: 5px 0;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    b {
      min-width: 150px !important;
      text-align: right;
      align-self: baseline;
      float: left;
    }
    span {
      width: calc(100% - 160px);
      // display: inline-block;
      // width: 120px;
      // margin-left: 100px;
      text-align: left;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}
.title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 18px;
  color: #303133;
}
.name{
   margin-right: 10px;
}
</style>
